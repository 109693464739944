






import { alimento_ficha } from "@/shared/dtos/visualizacion_dieta/alimento_ficha";
import { plato_ficha } from "@/shared/dtos/visualizacion_dieta/plato_ficha";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { UtilsNumber } from "@/utils/utils-number";
import { Component, Inject, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {
    EstructuraBasica: () =>
      import("@/views/base_alimentos/estructura_basica_ficha.vue"),
  },
})
export default class calculoEstructuraBasica extends Vue {
  @PropSync("estructura", { default: new plato_ficha() })
  datasource!: plato_ficha;
  @Inject("$validator") $validator!: any;

  @Prop({ default: false }) empezar_calculos!: boolean;

  private estoy_en_proceso = false;
  created() {}

  public cargar_datos() {
    if (this.estoy_en_proceso) {
      return;
    }
    this.estoy_en_proceso = true;
    if (this.empezar_calculos) {
      var ids = this.datasource.alimentos.map((x) => {
        let index = alimentoModule.local_db_alimentos_fichas.findIndex(
          (k) => k.id_estructura === x.id_estructura_alimento
        );

        if (index >= 0) {
          return 0;
        }
        return x.id_estructura_alimento;
      });
      ids = ids.filter((x) => x > 0);
      if (ids.length > 0) {
        alimentoModule.getalimentofichas(ids);
      }

      this.reset_estructura(this.datasource);
      for (let index = 0; index < this.datasource.alimentos.length; index++) {
        let alimento = alimentoModule.local_db_alimentos_fichas.find(
          (k) =>
            k.id_estructura ===
            this.datasource.alimentos[index].id_estructura_alimento
        );
        if (alimento !== undefined) {
          this.cal_estructura(
            this.datasource,
            this.datasource.alimentos[index].cantidad,
            alimento
          );
        }
      }
      let suma =
        Number.parseFloat(
          UtilsNumber.ValueToNumber(this.datasource.grasas_totales).toString()
        ) +
        Number.parseFloat(
          UtilsNumber.ValueToNumber(this.datasource.glucidos_totales).toString()
        ) +
        Number.parseFloat(
          UtilsNumber.ValueToNumber(
            this.datasource.proteinas_totales
          ).toString()
        );
      this.datasource.porcentaje_gr = UtilsNumber.RoudTwoDecimals(
        (this.metodo_getInt_correct(this.datasource.grasas_totales) * 100) /
          suma
      );

      this.datasource.porcentaje_glucidos = UtilsNumber.RoudTwoDecimals(
        (this.metodo_getInt_correct(this.datasource.glucidos_totales) * 100) /
          suma
      );
      this.datasource.porcentaje_protein = UtilsNumber.RoudTwoDecimals(
        (this.metodo_getInt_correct(this.datasource.proteinas_totales) * 100) /
          suma
      );
      this.datasource.porcentaje_gr = UtilsNumber.RoudTwoDecimals(
        this.datasource.porcentaje_gr / 100
      );
      this.datasource.porcentaje_glucidos = UtilsNumber.RoudTwoDecimals(
        this.datasource.porcentaje_glucidos / 100
      );
      this.datasource.porcentaje_protein = UtilsNumber.RoudTwoDecimals(
        this.datasource.porcentaje_protein / 100
      );
      this.redondear_estructura(this.datasource);
    }
    this.estoy_en_proceso = false;
    return "";
  }

  public cal_estructura(
    estructura: any,
    cantidad: number,
    alimento: alimento_ficha
  ) {
    estructura.energia =
      estructura.energia +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.energia, cantidad)
        ).toString()
      );
    estructura.etanol =
      estructura.etanol +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.etanol, cantidad)
        ).toString()
      );
    estructura.glucidos_totales =
      estructura.glucidos_totales +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.glucidos_totales, cantidad)
        ).toString()
      );
    estructura.polisacaridos =
      estructura.polisacaridos +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.polisacaridos, cantidad)
        ).toString()
      );
    estructura.azucares =
      estructura.azucares +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.azucares, cantidad)
        ).toString()
      );
    estructura.fibra =
      estructura.fibra +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.fibra, cantidad)
        ).toString()
      );
    estructura.grasas_totales =
      estructura.grasas_totales +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.grasas_totales, cantidad)
        ).toString()
      );
    estructura.agmi =
      estructura.agmi +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.agmi, cantidad)
        ).toString()
      );
    estructura.agpi =
      estructura.agpi +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.agpi, cantidad)
        ).toString()
      );
    estructura.ags =
      estructura.ags +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.ags, cantidad)
        ).toString()
      );
    estructura.colesterol =
      estructura.colesterol +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.colesterol, cantidad)
        ).toString()
      );
    estructura.proteinas_totales =
      estructura.proteinas_totales +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.proteinas_totales, cantidad)
        ).toString()
      );
    estructura.proteinas_animales =
      estructura.proteinas_animales +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.proteinas_animales, cantidad)
        ).toString()
      );
    estructura.proteinas_vegetales =
      estructura.proteinas_vegetales +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(
            alimento.proteinas_vegetales,
            cantidad
          )
        ).toString()
      );
    estructura.iodo =
      estructura.iodo +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.iodo, cantidad)
        ).toString()
      );
    estructura.sodio =
      estructura.sodio +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.sodio, cantidad)
        ).toString()
      );
    estructura.potasio =
      estructura.potasio +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.potasio, cantidad)
        ).toString()
      );
    estructura.calcio =
      estructura.calcio +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.calcio, cantidad)
        ).toString()
      );
    estructura.magnesio =
      estructura.magnesio +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.magnesio, cantidad)
        ).toString()
      );
    estructura.fosforo =
      estructura.fosforo +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.fosforo, cantidad)
        ).toString()
      );
    estructura.hierro =
      estructura.hierro +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.hierro, cantidad)
        ).toString()
      );
    estructura.zinc =
      estructura.zinc +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.zinc, cantidad)
        ).toString()
      );
    estructura.selenio =
      estructura.selenio +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.selenio, cantidad)
        ).toString()
      );
    estructura.vitamina_b1 =
      estructura.vitamina_b1 +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_b1, cantidad)
        ).toString()
      );
    estructura.vitamina_b2 =
      estructura.vitamina_b2 +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_b2, cantidad)
        ).toString()
      );
    estructura.vitamina_b6 =
      estructura.vitamina_b6 +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_b6, cantidad)
        ).toString()
      );
    estructura.vitamina_b12 =
      estructura.vitamina_b12 +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_b12, cantidad)
        ).toString()
      );
    estructura.acido_folico =
      estructura.acido_folico +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.acido_folico, cantidad)
        ).toString()
      );
    estructura.niacina =
      estructura.niacina +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.niacina, cantidad)
        ).toString()
      );
    estructura.vitamina_c =
      estructura.vitamina_c +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_c, cantidad)
        ).toString()
      );
    estructura.vitamina_a =
      estructura.vitamina_a +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_a, cantidad)
        ).toString()
      );
    estructura.vitamina_d =
      estructura.vitamina_d +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_d, cantidad)
        ).toString()
      );
    estructura.vitamina_e =
      estructura.vitamina_e +
      Number.parseFloat(
        UtilsNumber.ValueToNumber(
          this.caulcula_dato_proporcional(alimento.vitamina_e, cantidad)
        ).toString()
      );
  }

  public reset_estructura(estructura: any) {
    estructura.energia = 0;
    estructura.etanol = 0;
    estructura.glucidos_totales = 0;
    estructura.polisacaridos = 0;
    estructura.azucares = 0;
    estructura.fibra = 0;
    estructura.grasas_totales = 0;
    estructura.agmi = 0;
    estructura.agpi = 0;
    estructura.ags = 0;
    estructura.colesterol = 0;
    estructura.proteinas_totales = 0;
    estructura.proteinas_animales = 0;
    estructura.proteinas_vegetales = 0;
    estructura.iodo = 0;
    estructura.sodio = 0;
    estructura.potasio = 0;
    estructura.calcio = 0;
    estructura.magnesio = 0;
    estructura.fosforo = 0;
    estructura.hierro = 0;
    estructura.zinc = 0;
    estructura.selenio = 0;
    estructura.vitamina_b1 = 0;
    estructura.vitamina_b2 = 0;
    estructura.vitamina_b6 = 0;
    estructura.vitamina_b12 = 0;
    estructura.acido_folico = 0;
    estructura.niacina = 0;
    estructura.vitamina_c = 0;
    estructura.vitamina_a = 0;
    estructura.vitamina_d = 0;
    estructura.vitamina_e = 0;
  }

  public redondear_estructura(estructura: any) {
    try {
      estructura.energia = UtilsNumber.RoudTwoDecimals(estructura.energia);
      estructura.etanol = UtilsNumber.RoudTwoDecimals(estructura.etanol);
      estructura.glucidos_totales = UtilsNumber.RoudTwoDecimals(
        estructura.glucidos_totales
      );
      estructura.polisacaridos = UtilsNumber.RoudTwoDecimals(
        estructura.polisacaridos
      );
      estructura.azucares = UtilsNumber.RoudTwoDecimals(estructura.azucares);
      estructura.fibra = UtilsNumber.RoudTwoDecimals(estructura.fibra);
      estructura.grasas_totales = UtilsNumber.RoudTwoDecimals(
        estructura.grasas_totales
      );
      estructura.agmi = UtilsNumber.RoudTwoDecimals(estructura.agmi);
      estructura.agpi = UtilsNumber.RoudTwoDecimals(estructura.agpi);
      estructura.ags = UtilsNumber.RoudTwoDecimals(estructura.ags);
      estructura.colesterol = UtilsNumber.RoudTwoDecimals(
        estructura.colesterol
      );
      estructura.proteinas_totales = UtilsNumber.RoudTwoDecimals(
        estructura.proteinas_totales
      );
      estructura.proteinas_animales = UtilsNumber.RoudTwoDecimals(
        estructura.proteinas_animales
      );
      estructura.proteinas_vegetales = UtilsNumber.RoudTwoDecimals(
        estructura.proteinas_vegetales
      );
      estructura.iodo = UtilsNumber.RoudTwoDecimals(estructura.iodo);
      estructura.sodio = UtilsNumber.RoudTwoDecimals(estructura.sodio);
      estructura.potasio = UtilsNumber.RoudTwoDecimals(estructura.potasio);
      estructura.calcio = UtilsNumber.RoudTwoDecimals(estructura.calcio);
      estructura.magnesio = UtilsNumber.RoudTwoDecimals(estructura.magnesio);
      estructura.fosforo = UtilsNumber.RoudTwoDecimals(estructura.fosforo);
      estructura.hierro = UtilsNumber.RoudTwoDecimals(estructura.hierro);
      estructura.zinc = UtilsNumber.RoudTwoDecimals(estructura.zinc);
      estructura.selenio = UtilsNumber.RoudTwoDecimals(estructura.selenio);
      estructura.vitamina_b1 = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_b1
      );
      estructura.vitamina_b2 = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_b2
      );
      estructura.vitamina_b6 = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_b6
      );
      estructura.vitamina_b12 = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_b12
      );
      estructura.acido_folico = UtilsNumber.RoudTwoDecimals(
        estructura.acido_folico
      );
      estructura.niacina = UtilsNumber.RoudTwoDecimals(estructura.niacina);
      estructura.vitamina_c = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_c
      );
      estructura.vitamina_a = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_a
      );
      estructura.vitamina_d = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_d
      );
      estructura.vitamina_e = UtilsNumber.RoudTwoDecimals(
        estructura.vitamina_e
      );
    } catch (error) {}
  }
  public caulcula_dato_proporcional(valor_100g: string, gramos: number) {
    return UtilsNumber.RoudTwoDecimals(
      (Number.parseFloat(UtilsNumber.ValueToNumber(valor_100g).toString()) *
        gramos) /
        100
    );
  }

  public metodo_getInt_correct(valor: any) {
    return UtilsNumber.RoudTwoDecimals(
      Number.parseFloat(UtilsNumber.ValueToNumber(valor).toString())
    );
  }
}
